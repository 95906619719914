import { useState } from "react";
import StrandInputBlock from "../components/StrandInputBlock";
import { high_numberOfStrands, high_strands} from "../components/high_immunostimulatory_NANP_2024.js";  //SJ_test
import { low_numberOfStrands, low_strands} from "../components/low_immunostimulatory_NANP_2024.js";  //SJ_test

import '../components/botton_high_low_color.css'; // Import the CSS file  //SJ_test

const axios = require('axios').default;


const PREDICT_API_ENDPOINT = `http://127.0.0.1:5000/predict`; 								//SJ_test
//const PREDICT_API_ENDPOINT = `${process.env.REACT_APP_API_BASE_URI}/predict`;
const DEFAULT_NO_OF_STRANDS = process.env.REACT_APP_DEFAULT_NO_OF_STRANDS ||4;

const getBlankStandListOfLength = (length, startingIndex = 1) => {
  const list = [];
  for (let i = 0; i < length; i++) {
    list.push({
      Strand_Name: `Strand${startingIndex + i}`,
      sequence: '' 
    });
  }

  return list;
};


const PredictInHumanMicroglia = () => {
	const [totalStrands, setTotalStrands] = useState(DEFAULT_NO_OF_STRANDS);

  const [result, setResult] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [strands, setStrands] = useState(getBlankStandListOfLength(DEFAULT_NO_OF_STRANDS));

	const doPredict = () => {
		axios.post(PREDICT_API_ENDPOINT, strands).then(response => {
      console.log("response", response);
      setResult(response.data);
      console.log(response?.data?.Prediction);
      setSuccess(response?.data?.Prediction === 'Success');
    });
	}

	const handleReset_high_immuno_nanp_2024 = () => {
		const newStrands = high_strands.map((sequence, index) => ({
		  Strand_Name: `Strand${index + 1}`,
		  sequence: sequence
		}));
		setStrands(newStrands);
		setTotalStrands(high_numberOfStrands); // Update total strands
	
		setResult({
			'IFN-b_2024_activity': 792.2,
			'IFN-il6_activity': 4247.2,
		});
		setSuccess(true);
	  };


	  const handleReset_low_immuno_nanp_2024 = () => {
		const newStrands = low_strands.map((sequence, index) => ({
		  Strand_Name: `Strand${index + 1}`,
		  sequence: sequence
		}));
		setStrands(newStrands);
		setTotalStrands(low_numberOfStrands); // Update total strands
	
		setResult({
		  'IFN-b_2024_activity': 36.6,
		  'IFN-il6_activity': 786.5,
		});
		setSuccess(true);
	  };

/*
	  // New function to format activity values //SJ
	  const formatActivityValue = (value) => {
		const numericValue = parseFloat(value);
		if (isNaN(numericValue)) {
		  return 'N.A';
		}
		if (numericValue === 0) {
		  return '0.00';
		}
		return numericValue.toFixed(2);
	  };
*/

const formatActivityValue = (value) => {
	if (value === null || value === undefined) {
	  return 'N.A';
	}
  
	// Check if the value contains an uncertainty component (±)
	if (typeof value === 'string' && value.includes('±')) {
	  return value; // Return the value as-is if it contains an uncertainty component
	}
  
	const numericValue = parseFloat(value);
	if (isNaN(numericValue)) {
	  return 'N.A';
	}
	if (numericValue === 0) {
	  return '0.00';
	}
	return numericValue.toFixed(2);
  };

  const onChangeStandNumber = (count) => {
    setTotalStrands(count);
    
    if (count < strands.length) {
      setStrands([...strands].slice(0, count));
    } else {
      setStrands([...strands, ...getBlankStandListOfLength(count - strands.length, strands.length + 1)])
    }
  }

	const renderStrandBlocks = () => {
    return strands.map((strand, i) =>
      <StrandInputBlock
        index={i}
        key={i}
        value={strand}
        onChange={(val) => setStrandValueAtIndex(i, val)}
      />
    )
	};

  const setStrandValueAtIndex = (index, strand) => {
    const newStrandList = [...strands];
    newStrandList[index] = strand;
    setStrands(newStrandList);
  };

	return (
		<>
      <div>
        <br/>
        <h3>Predict in human microglia-like cell line</h3>
        <p className="text-justify">
          Microglia are tissue resident cells of the brain parenchyma that contribute to regulation of brain development and tissue homeostasis. These macrophage-like cells provide important immune surveillance to the brain which is often considered immune-privileged due to the blood-brain barrier. Notably, it is now recognized that microglia contribute to neurodegenerative and neuroprotective responses during disease of the central nervous system. Therefore, microglia are a relevant model for assessing the immunological profiles of novel formulations that may be employed in the treatment of neurological diseases. The immortalized primary human microglia employed in these studies were a generous gift from Dr Jonathan Karn (Case Western Reserve University). Primary human cells were transformed with lentiviral vectors expressing SV40 T antigen and hTERT followed by characterization of cellular morphology, migratory and phagocytic activity, surface markers, and RNA expression profile (<a href="https://pubmed.ncbi.nlm.nih.gov/27873219/">Immortalization of primary microglia: a new platform to study HIV regulation in the central nervous system</a>).
        </p>

		{/* Moved the botton SJ_test*/}
		<div className="mt-2 text-start d-flex justify-content-start">
          <button className="btn btn-primary btn-high-immuno me-2" onClick={handleReset_high_immuno_nanp_2024}>Example: High Immunostimulatory NANP</button>
          <button className="btn btn-primary btn-low-immuno me-2" onClick={handleReset_low_immuno_nanp_2024}>Example: Low Immunostimulatory NANP</button>
        </div>



      </div>
      <hr/>
			<div className="d-flex align-items-center my-3">
				<p className="text-muted mb-0 me-2">Number of strands:</p>
				<select
          value={totalStrands}
					className="form-select form-select"
					onChange={(e) => onChangeStandNumber(e?.target?.value, 10)}
					style={{ width: "100px" }}
				>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
					<option value="6">6</option>
					<option value="7">7</option>
					<option value="8">8</option>
				</select>
			</div>

			<div className="p-3 mb-3 border">
				<h4 className="text-secondary">Input Sequences</h4>
				  { renderStrandBlocks() }
				  <div className="mt-2 text-end">
					
					{/*	
					<div>
            			<button className="btn btn-primary text-white trigger-predict-btn me-2" onClick={handleReset_high_immuno_nanp_2024}style={{ backgroundColor: 'red' }}>Example: High Immunostimulatory NANP</button>
            			<button className="btn btn-primary text-white trigger-predict-btn me-2" onClick={handleReset_low_immuno_nanp_2024}style={{ backgroundColor: 'green' }}>Example: Low Immunostimulatory NANP</button>
					</div>

					*/}	
						<button className="btn btn-predict" onClick={doPredict}>Predict</button>
						{/*<button className="btn btn-primary text-white trigger-predict-btn" onClick={doPredict}>Predict</button>*/}

				</div>
			</div>

			<div className="p-3 border">
				<h4 className="text-secondary mb-3">Prediction Results: <span className={isSuccess ? 'text-success' : 'text-danger'}>{result.Prediction || 'N.A'}</span></h4>
				<div className="row row-cols-2 gy-2">
          <div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-β Activity (ng/ml):</div>
							{/* <div className="value">{result['IFN-b_2024_activity'] || 'N.A'}</div>*/}
							<div className="value">{formatActivityValue(result['IFN-b_2024_activity'])}</div> {/* Updated display logic---SJ_test*/}
						</div>
					</div>
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-IL-6 Activity (ng/ml):</div>
							{/* <div className="value">{result['IFN-il6_activity'] || 'N.A'}</div>*/}
							<div className="value">{formatActivityValue(result['IFN-il6_activity'])}</div> {/* Updated display logic---SJ_test*/}
						</div>
					</div>

					{/* <div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-λ Activity (pg/ml):</div>
							<div className="value">{result['IFN-h_activity'] || 'N.A'}</div>
						</div>
					</div>
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-ω Activity (pg/ml):</div>
							<div className="value">{result['IFN-w_activity'] || 'N.A'}</div>
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default PredictInHumanMicroglia;

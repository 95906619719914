//import React, { useState } from 'react'; // Add useState import from React  //SJ_test

import { useState } from "react";
import StrandInputBlock from "../components/StrandInputBlock";
import { high_numberOfStrands, high_strands} from "../components/high_immunostimulatory_NANP.js";  //SJ_test
import { low_numberOfStrands, low_strands} from "../components/low_immunostimulatory_NANP.js";  //SJ_test

import '../components/botton_high_low_color.css'; // Import the CSS file  //SJ_test



const axios = require('axios').default;

const PREDICT_API_ENDPOINT = `http://127.0.0.1:5000/predict`; 								//SJ_test
//const PREDICT_API_ENDPOINT = `${process.env.REACT_APP_API_BASE_URI}/predict`;
const DEFAULT_NO_OF_STRANDS = process.env.REACT_APP_DEFAULT_NO_OF_STRANDS || 4;

const getBlankStandListOfLength = (length, startingIndex = 1) => {
  const list = [];
  for (let i = 0; i < length; i++) {
    list.push({
      Strand_Name: `Strand${startingIndex + i}`,
      sequence: '' 
    });
  }

  return list;
};


const PredictInHumanPBMC = () => {
	const [totalStrands, setTotalStrands] = useState(DEFAULT_NO_OF_STRANDS);

  const [result, setResult] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [strands, setStrands] = useState(getBlankStandListOfLength(DEFAULT_NO_OF_STRANDS));
  const [values, setValues] = useState(high_strands); // SJ_try



	const doPredict = () => {
		axios.post(PREDICT_API_ENDPOINT, strands).then(response => {
      console.log("response", response);
      setResult(response.data);
      console.log(response?.data?.Prediction);
      setSuccess(response?.data?.Prediction === 'Success');
    });
	}


	  const handleReset_high_immuno_nanp = () => {
		const newStrands = high_strands.map((sequence, index) => ({
		  Strand_Name: `Strand${index + 1}`,
		  sequence: sequence
		}));
		setStrands(newStrands);
		setTotalStrands(high_numberOfStrands); // Update total strands
	
		setResult({
		  'IFN-a_activity': 18378.61,
		  'IFN-b_activity': 356.33,
		  'IFN-h_activity': 578.23,
		  'IFN-w_activity': 683.99
		});
		setSuccess(true);
	  };


	  const handleReset_low_immuno_nanp = () => {
		const newStrands = low_strands.map((sequence, index) => ({
		  Strand_Name: `Strand${index + 1}`,
		  sequence: sequence
		}));
		setStrands(newStrands);
		setTotalStrands(low_numberOfStrands); // Update total strands
	
		setResult({
		  'IFN-a_activity': 3908.28,
		  'IFN-b_activity': 27.16,
		  'IFN-h_activity': 0.00,
		  'IFN-w_activity': 179.05
		});
		setSuccess(true);
	  };
/*
	  // New function to format activity values //SJ
	  const formatActivityValue = (value) => {
		const numericValue = parseFloat(value);
		if (isNaN(numericValue)) {
		  return 'N.A';
		}
		if (numericValue === 0) {
		  return '0.00';
		}
		return numericValue.toFixed(2);
	  };
*/

const formatActivityValue = (value) => {
	if (value === null || value === undefined) {
	  return 'N.A';
	}
  
	// Check if the value contains an uncertainty component (±)
	if (typeof value === 'string' && value.includes('±')) {
	  return value; // Return the value as-is if it contains an uncertainty component
	}
  
	const numericValue = parseFloat(value);
	if (isNaN(numericValue)) {
	  return 'N.A';
	}
	if (numericValue === 0) {
	  return '0.00';
	}
	return numericValue.toFixed(2);
  };




  const onChangeStandNumber = (count) => {
    setTotalStrands(count);
    
    if (count < strands.length) {
      setStrands([...strands].slice(0, count));
    } else {
      setStrands([...strands, ...getBlankStandListOfLength(count - strands.length, strands.length + 1)])
    }
  }

	const renderStrandBlocks = () => {
    return strands.map((strand, i) =>
      <StrandInputBlock
        index={i}
        key={i}
        value={strand}
        onChange={(val) => setStrandValueAtIndex(i, val)}
      />
    )
	};

  const setStrandValueAtIndex = (index, strand) => {
    const newStrandList = [...strands];
    newStrandList[index] = strand;
    setStrands(newStrandList);
  };

	return (
		<>
      <div>
        <br/>
        <h3>Predict in human PBMCs</h3>
        <p className="text-justify">
          The peripheral blood mononuclear cells (PBMCs) model is recognized for its accurate prediction of cytokine storm-related immunotoxicities observed in patients. This model has demonstrated superior predictive power and reliability compared to traditional rodent and non-human primate models. Freshly isolated PBMCs provide a clinically relevant physiological environment for studying human immune responses with the ability to mimic the complex interactions and responses of the human immune system. This makes PBMCs a well-accepted tool in preclinical research, particularly for assessing immunological profiles of novel formulations.
        </p>
		{/* Moved the botton SJ_test*/}
		<div className="mt-2 text-start d-flex justify-content-start">
          <button className="btn btn-high-immuno me-2" onClick={handleReset_high_immuno_nanp}>Example: High Immunostimulatory NANP</button>
          <button className="btn btn-low-immuno me-2" onClick={handleReset_low_immuno_nanp}>Example: Low Immunostimulatory NANP</button>
        </div>


      </div>
      <hr/>
			<div className="d-flex align-items-center my-3">
				<p className="text-muted mb-0 me-2">Number of strands:</p>
				<select
          value={totalStrands}
					className="form-select form-select"
					onChange={(e) => onChangeStandNumber(e?.target?.value, 10)}
					style={{ width: "100px" }}
				>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
					<option value="6">6</option>
					<option value="7">7</option>
					<option value="8">8</option>
				</select>
			</div>

			<div className="p-3 mb-3 border">
				<h4 className="text-secondary">Input Sequences</h4>
				  { renderStrandBlocks() }
				<div className="mt-2 text-end">
					
				{/*	
					<div>
            			<button className="btn btn-primary text-white trigger-predict-btn me-2" onClick={handleReset_high_immuno_nanp}style={{ backgroundColor: 'red' }}>Example: High Immunostimulatory NANP</button>
            			<button className="btn btn-primary text-white trigger-predict-btn me-2" onClick={handleReset_low_immuno_nanp}style={{ backgroundColor: 'green' }}>Example: Low Immunostimulatory NANP</button>
					</div>
				*/}	
						<button className="btn btn-predict" onClick={doPredict}>Predict</button>
						{/*<button className="btn btn-primary text-white trigger-predict-btn" onClick={doPredict}>Predict</button>*/}
				
				</div>
			</div>
			<div className="p-3 border">
				<h4 className="text-secondary mb-3">Prediction Results: <span className={isSuccess ? 'text-success' : 'text-danger'}>{result.Prediction || 'N.A'}</span></h4>
				<div className="row row-cols-2 gy-2">
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-α Activity (pg/ml):</div>
							{/*<div className="value">{result['IFN-a_activity'] || 'N.A'}</div>*/}
							<div className="value">{formatActivityValue(result['IFN-a_activity'])}</div> {/* Updated display logic---SJ_test*/}
						</div>
					</div>
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-β Activity (pg/ml):</div>
							{/*<div className="value">{result['IFN-b_activity'] || 'N.A'}</div>*/}
							<div className="value">{formatActivityValue(result['IFN-b_activity'])}</div> {/* Updated display logic---SJ_test*/}

						</div>
					</div>
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-λ Activity (pg/ml):</div>
							{/*<div className="value">{result['IFN-h_activity'] || 'N.A'}</div>*/}
							<div className="value">{formatActivityValue(result['IFN-h_activity'])}</div> {/* Updated display logic---SJ_test*/}
						</div>
					</div>
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-ω Activity (pg/ml):</div>
							{/*<div className="value">{result['IFN-w_activity'] || 'N.A'}</div>*/}
							<div className="value">{formatActivityValue(result['IFN-w_activity'])}</div> {/* Updated display logic---SJ_test*/}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PredictInHumanPBMC;

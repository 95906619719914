import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import PredictInHumanPBMC from "./pages/PredictInHumanPBMC";
import PredictInHumanMicroglia from "./pages/PredictInHumanMicroglia";
import Contact from "./pages/Contact";
import Funding from "./pages/Funding";
import References from "./pages/References";

const App = () => {
	return (
		<Router>
			<Layout>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/predict-in-human-pbmc" component={PredictInHumanPBMC} />
          <Route exact path="/predict-in-human-microglia" component={PredictInHumanMicroglia} />
					<Route path="/contact" component={Contact} />
					<Route path="/funding" component={Funding} />
					<Route path="/references" component={References} />
					<Route path="*">
						<Redirect to="/" />
					</Route>
				</Switch>
			</Layout>
		</Router>
	);
};

export default App;

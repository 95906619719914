import { NavLink } from "react-router-dom";

const Header = () => {
	return (
		<>
			<header>
				<div className="d-flex flex-column flex-md-row align-items-center mb-2 border-bottom">
					<a href="/" className="d-flex align-items-center text-dark text-decoration-none">
						<span className="site-title fs-1"><i>AI-cell</i></span>
					</a>

					<nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
						<NavLink activeClassName='active' to='/predict-in-human-pbmc' className="nav-link fs-5 px-2 text-dark text-decoration-none">Predict in Human PBMC</NavLink>
            			<NavLink activeClassName='active' to='/predict-in-human-microglia' className="nav-link fs-5 px-2 text-dark text-decoration-none">Predict in Human Microglia</NavLink>
						<NavLink activeClassName='active' to='/references' className="nav-link fs-5 px-2 text-dark text-decoration-none">References</NavLink>
						<NavLink activeClassName='active' to='/funding' className="nav-link fs-5 px-2 text-dark text-decoration-none">Funding</NavLink>
						<NavLink activeClassName='active' to='/contact' className="nav-link fs-5 px-2 text-dark text-decoration-none">Contact</NavLink>
					</nav>
				</div>
			</header>
		</>
	);
};

export default Header;
